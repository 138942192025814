.wrapper {

}


.page  {
  background-color: #000;
  position: relative;
  width: 100vw;
}

.first {
  background-size: cover;
}

.second {
  background-color: blue;
}

.introWrapper {
  background-color: #fff;
  padding: 32px;
}

.detailsWrapper {
  background-color: #33cc01;
  padding: 32px;
}

.detailsWrapper2 {
  background-color: #006800;
  color: #fff;
  padding: 32px;
}

.linkWrapper {
  bottom: 48px;
  display: none;
  position: absolute;
  text-align: center;
  width: 100%;
}

.mainImage {
  display: none;
  max-width: 100%;
}

.mainText {
  margin: 32px 0;
}

.form {
  background-color: #fff;
  padding: 24px 16px;
  margin: 32px 0;
}

.logoWrapper {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1024px) {
  .page  {
    display: flex;
    height: 100vh;
  }

  .introWrapper {
    width: 50%;
  }

  .detailsWrapper {
    width: 50%;
  }

  .detailsWrapper2 {
    width: 50%;
  }

  .linkWrapper {
    display: block;
  }

  .mainImage {
    display: block;
  }

  .form {
    margin: 32px 40px;
  }

  .logoWrapper {
    justify-content: flex-start;
  }
}
