.wrapper {
  background-color: #33cc01;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 416px;
  padding: 8px;
}

.content {
  flex: 1 0 auto;
  margin-bottom: 16px;
}

.footer {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding-bottom: 48px;
}

.list {
  color: #fff;
  list-style-type: none;
  margin: 32px 24px 0;
  padding: 0;
}

.listItem {
  margin-top: 16px;
}

.listItem:first-child {
  margin-top: 0;
}
